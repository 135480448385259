body {
  background: #E2E4E7;
  padding: 0;
  position: relative;
  margin: 0;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1.5rem;
  line-height: 2rem;
  height: 100%;
  overflow: hidden;
}
a {
  color: #b72236;
  text-decoration: none;
  text-shadow: 1px 1px 1px #fff;
}
a:hover,
a:focus {
  color: #761623;
  text-decoration: underline;
}

.App {
  text-shadow: 1px 1px 3px #111;
  color: white;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.page-wrap {
  overflow: auto;
  height: 100vh;
}

nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
  outline: none;
  margin: 0 0 2rem 0;
}
nav ul li {
  padding: 0.15rem 0 0.35rem 0;
  margin: 0 0.5rem;
  border-bottom: 1px solid #CFCFCF;
  text-align: center;

}

header {
  position: fixed;
  z-index: 1030;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.logoLink {
  height: 50px;
  align-self: flex-end;
  margin: 10px;
}
.logo {
  width: 100px;
  transition: 1s ease-in-out;
}

.button {
  margin: 20px;
  padding: 10px 20px;
  background-color: #b72236;
  color: #FFF;
  display: inline-block;
  text-align: center;
  text-shadow: none;
}
.button:hover {
  text-decoration: none;
  background-color: #761623;
  color: #FFFFFF;
}



.defaultText {
  text-align: left;
}
.text-right {
  text-align: right;
}

.defaultContent {
  padding: 80px 0 ;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  overflow: auto;
  box-sizing: border-box;
}



@media only screen and (min-width: 576px) {
  .logo {
    width: 150px;
    height: auto;
    align-self: center;

  }
  .logoLink {
    margin: 10px 20px ;
  }

}

nav {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

h1{
  font-size: 2rem;
  line-height: normal;
}

h2{
  font-size: 2.5rem;
  line-height: normal;
}

@media only screen and (min-width: 768px) {

}

@media only screen and (min-width: 992px) {

}

@media only screen and (min-width: 1200px) {

}
